<template>
  <div class="trends-page">
    <v-container class="pa-0 ma-0 text-start">
      <v-img
        :src="require(`@/assets/img/arrowleft.svg`)"
        contain
        @click="
          () => {
            $router.go(-1);
          }
        "
        class="mr-2"
        max-width="23"
      />
      <span
        @click="
          () => {
            $router.go(-1);
          }
        "
        class="back-text"
        >Volver a Tendencias</span
      >
    </v-container>
    <div class="article pt-0">
      <v-container>
        <breadcrumbs :items="breadcrumbs" />
        <div class="tway-headline-2 tway-morado--text">
          {{ data.title }}
        </div>
        <div class="tway-body grey--text my-3">
          {{ data.author }}
        </div>
        <div class="tway-headline-3 black--text py-3" style="max-width: 730px">
          {{ data.subtitle }}
        </div>
        <v-layout row>
          <v-flex xs12 md4 v-if="data.coverUrl" class="px-3">
            <v-img :src="data.coverUrl" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
            <div class="tway-body grey--text mt-2">
              {{ data.publisher }}
            </div>
          </v-flex>
          <v-flex xs12 md8 class="px-3">
            <div v-if="data.introHtml !== null">
              <p v-html="data.introHtml" />
            </div>
          </v-flex>
        </v-layout>
        <div v-if="data.url">
          <v-btn text :href="data.url" target="_blank" color="tway-morado">
            <v-icon left> mdi-open-in-new </v-icon>
            Ver artículo completo aquí
          </v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-start {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  div,
  span {
    cursor: pointer;
  }
}
.back-text {
  font-size: 16px;
  color: #999999;
}
</style>

<script>
import TrendService from "@/services/trendService";
import Breadcrumbs from "@/components/Breadcrumbs";
import CompanyService from "@/services/companyService";

export default {
  name: "TrendPost",

  components: {
    Breadcrumbs,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Tendencias",
        disabled: false,
        exact: true,
        to: {
          name: "trends",
        },
      },
      {
        text: "",
        disabled: true,
        exact: true,
        to: {
          name: "trends-post",
        },
      },
    ],
    clientType: "",
    data: {},
  }),

  created() {
    this.getCompany();
    window.scrollTo(0, 0);
    TrendService.getTrend(this.$route.params.id)
      .then((response) => {
        this.data = response;
      })
      .catch((err) => this.$log.error(err));
  },

  methods: {
    getCompany() {
      CompanyService.getCompanyProfile()
        .then((company) => (this.clientType = this._.get(company, "type")))
        .catch((err) => console.log(err, `54a2b338_0`));
    },
    getDate(timestamp) {
      let unix_timestamp = timestamp;
      var date = new Date(unix_timestamp);

      return date.toLocaleDateString("es", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.article {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
